<template>
  <div class="bigDiv">
    <van-overlay :show="show">
      <div class="wrapper" @click.stop>
        <div class="binner" v-if="showZhiyin">
          <img src="../../assets/images/foodList/bananer.png" alt="">
          <div style="display:flex">
            <div class="xiayibu diyi tiaoguo" @click="wancheng">跳过</div>
            <div class="xiayibu diyi" @click="xiayi">下一步</div>
          </div>

        </div>
        <div class="binner" v-if="showZhiyinA">
          <img src="../../assets/images/foodList/bananer2.png" alt="">
          <div style="display:flex">
            <div class="xiayibu wancheng" @click="wancheng">立即体验</div>

          </div>

        </div>
      </div>
    </van-overlay>

    <div>
      <div class="topImg">
        <img src="../../assets/images/foodList/searchTop.jpg" alt="">
      </div>
      <div class="topSarch">
        <van-field v-model="text" placeholder="请输入食物名称可查询营养和热量" border @click="logo">
          <template #left-icon>
            <div class="leftIcon">
              <img src="../../assets/images/foodList/search.png" alt="">
            </div>
          </template>

          <template #button>
            <div class="serch">
              搜索</div>
          </template>
        </van-field>
      </div>
      <div class="searchTitle">
        <h3>搜索记录</h3>
        <div class="delImg" @click="delLIst"><img src="../../assets/images/foodList/dea.png" alt=""></div>
      </div>
      <div class="tag">
        <span class="tags" v-for="item in listKeys " :key="item" @click="listLishi(item)">
          {{item}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { getWechatConfig } from "@/api/yangsheng";

export default {
  name: '',
  props: {
  },
  components: {

  },
  data () {
    return {
      searchText: '',
      text: '',
      show: false,
      showZhiyin: false,
      showZhiyinA: false,
      listKeys: []
    }
  },
  created () {
    const token = localStorage.getItem('token')
    if (token) {
      this.show = false
      this.showZhiyin = false
    }
    this.listKeys = JSON.parse(localStorage.getItem('listKeys'))
  },
  methods: {
    async getCode (type) {
      console.log(123, "获取code");

      let scope = "snsapi_userinfo"; //手动授权
      let info = {
        appid: "wxecd388507aac29b6"
      };
      // if (this.info.belong === 2) {
      //   info = {
      //     appid: "wx64571f72789c7dd3"
      //   };
      // } else if (this.info.belong === 3) {
      //   info = {
      //     appid: "wx109c3a30148f3fb8"
      //   };
      // } else if (this.info.belong === 7) {
      //   info = {
      //     appid = ""
      //   }
      // }

      // const res = await getCompanyById({
      //   id: this.info.company_id
      // });
      // info.appid = res.data.app_id;
      // !isCode
      // const token = sessionStorage.getItem("assice_token");
      // if (this.$route.query.code && !token) {
      //   this.getAccountOauthCodes();

      //   this.loading = true;
      //   console.log(this.$route.query.code, "我是code");

      //   // this.getOpenId(this.$route.query.code as string);
      // } else if (this.$route.query.code && token) {
      //   // TODO
      // } else {
      const parsedUrl = new URL(window.location.href);
      const basAAA = parsedUrl.origin;
      // channelLogin: this.$route.query.channelLogin || ""
      // nickname: this.$route.query.nickname || ""
      const redirectUrl = basAAA;
      // const redirectUrl = "http://localhost:8081";
      // `https://open.weixin.qq.com/connect/oauth2/authorize?appid=APPID&redirect_uri=REDIRECT_URI&response_type=code&scope=SCOPE&state=STATE#wechat_redirect`;
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${info.appid
        }&redirect_uri=${encodeURIComponent(
          `${redirectUrl}/searchFood?searchText=${type ? type : ''}`
        )}&response_type=code&scope=${scope}#wechat_redirect`;
      // }
    },
    getWechatConfig () {
      console.log(wx, 888888888);

      const query = {
        company_id: 1,
        url: window.location.href
      }
      getWechatConfig(query).then(res => {
        const agent = res.data.data
        if (res.data.code === 200) {
          this.$nextTick(() => {

          })
          wx.config({
            debug: agent.debug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: agent.appId, // 必填，公众号的唯一标识
            timestamp: agent.timestamp, // 必填，生成签名的时间戳
            nonceStr: agent.nonceStr, // 必填，生成签名的随机串
            signature: agent.signature,// 必填，签名
            //1.4.0的分享接口,注意：使用新版本的分享功能，一定要在该列表加上对应的老版本功能接口，否则新接口不起作用
            //     "onMenuShareAppMessage", //老版本分享接口。
            //     "onMenuShareTimeline" //老版本分享接口。
            jsApiList: ["updateAppMessageShareData", "updateTimelineShareData,'onMenuShareAppMessage", "onMenuShareTimeline"] // 必填，需要使用的JS接口列表
          });

          this.share()
        }

      }
      )
    },
    share () {
      this.$nextTick(() => {
        wx.ready(function () {
          // 分享给朋友
          wx.updateAppMessageShareData({
            title: '食物搜索', // 分享标题
            link: 'https://kf.hshwhkj.com/foodList', //这里可带参数，必须是同配置的JS安全域名一致
            desc: '营养食疗专业查询工具', // 分享描述
            imgUrl: 'https://oss.hshwhkj.com/hshcrm/image/2024/07/09/172049606735669199.png',// 必须是同配置的JS安全域名一致
            success: function () { }
          });
          // 分享到朋友圈
          wx.updateTimelineShareData({
            title: '食物搜索', // 分享标题
            link: 'https://kf.hshwhkj.com/foodList', //这里可带参数，必须是同配置的JS安全域名一致
            desc: '营养食疗专业查询工具', // 分享描述
            imgUrl: 'https://oss.hshwhkj.com/hshcrm/image/2024/07/09/172049606735669199.png',// 必须是同配置的JS安全域名一致
            success: function () { }
          })

        });
        wx.error(res => {
          console.log('微信分享错误信息：', res)
        })
      })



    },

    listLishi (item) {
      this.$router.push({
        path: '/searchFood',
        query: {
          searchText: item
        }
      })
      // this.getCode(item)
    },
    delLIst () {
      console.log(1);
      this.listKeys = []
      localStorage.setItem('listKeys', JSON.stringify(this.listKeys))
    },
    logo () {
      // this.getCode()
      this.$router.push({
        path: '/searchFood'
      })
    },
    xiayi () {
      this.showZhiyin = false
      this.showZhiyinA = true
    },
    shangyibu () {
      this.showZhiyin = true
      this.showZhiyinA = false
    },
    wancheng () {
      this.showZhiyin = false
      this.showZhiyinA = false
      this.show = false
    }
  },
  mounted () {
    this.getWechatConfig()
  },
  watch: {

  },
  computed: {

  },
  filters: {

  }
}
</script>

<style scoped lang='less'>
.wrapper {
  display: flex;
  margin-top: 160px;
  .xiayibu {
    z-index: 3000 !important;
    position: relative;
    color: #fff;
    width: 80px;
    height: 40px;
    border-radius: 50px;
    background-color: #ffffff;
    color: #47a17e;
    text-align: center;
    line-height: 40px;
    margin: 10px;
    left: 180px;
  }
  .tiaoguo {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #ccc;
    color: #fff;
  }
  .wancheng {
    width: 120px;
    position: relative;
    left: 120px;
    background-color: #00a37c;
    color: #fff;
  }
  .diyi {
    position: relative;
    left: 160px;
    top: -30px;
  }
}
.binner {
  width: 100%;
  img {
    width: 100%;
    object-fit: contain;
  }
}
.topImg {
  width: 100%;
  img {
    width: 100%;
    object-fit: contain;
  }
}
.topSarch {
  width: 340px;
  height: 50px;
  margin: 0 auto;
  margin-top: -30px;
}
.serch {
  background-color: #00a37c;
  color: #fff;
  width: 60px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
}
.leftIcon {
  width: 40px;
  text-align: center;
  img {
    width: 20px;
    object-fit: contain;
    display: inline-block;
    margin-top: 10px;
  }
}
.searchTitle {
  margin: 20px 15px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 700;
  height: 20px;
  text-align: center;
  align-items: center;
  .delImg {
    width: 20px;
    img {
      width: 100%;
      object-fit: contain;
      display: inline-block;
      margin-top: 8px;
    }
  }
}
.tag {
  font-size: 12px;
  width: 360px;
  margin: 10px auto;
  .tags {
    min-width: 15px;
    text-align: center;
    height: 15px;
    line-height: 15px;
    background-color: #f8f8f8;
    color: #333333;
    display: inline-block;
    padding: 5px;
    margin: 5px;
    border-radius: 50px;
  }
}

@media screen and (min-width: 750px) {
  .bigDiv {
    width: 750px;
    margin: 0 auto;
    overflow: hidden;
    .wrapper {
      position: relative;
      top: -500px;
      transform: translate(30%);
      .binner {
        width: 700px;
        img {
          width: 100%;
          object-fit: contain;
        }
      }
      .xiayibu {
        z-index: 3000 !important;
        position: relative;
        color: #fff;
        width: 80px;
        height: 40px;
        border-radius: 50px;
        background-color: #ffffff;
        color: #47a17e;
        text-align: center;
        line-height: 40px;
        margin: 10px;
        left: 180px;
      }
      .tiaoguo {
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid #ccc;
        color: #fff;
      }
      .wancheng {
        width: 120px;
        position: relative;
        left: 260px;
        background-color: #00a37c;
        color: #fff;
      }
      .diyi {
        position: relative;
        left: 260px;
        top: -30px;
      }
    }
    .topImg {
      width: 100%;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
    .topSarch {
      width: 750px;
      height: 50px;
      margin: 0 auto;
      margin-top: -30px;
    }
    .serch {
      background-color: #00a37c;
      color: #fff;
      width: 60px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 14px;
    }
    .leftIcon {
      width: 40px;
      height: 50px;
      text-align: center;
      img {
        width: 20px;
        object-fit: contain;
        display: inline-block;
        margin-top: 15px;
      }
    }
    .searchTitle {
      margin: 20px 15px;
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 700;
      height: 20px;
      text-align: center;
      align-items: center;
      .delImg {
        width: 20px;
        img {
          width: 100%;
          object-fit: contain;
          display: inline-block;
          margin-top: 8px;
        }
      }
    }
    .tag {
      font-size: 12px;
      width: 750px;
      margin: 10px auto;
      .tags {
        min-width: 15px;
        text-align: center;
        height: 15px;
        line-height: 15px;
        background-color: #f8f8f8;
        color: #333333;
        display: inline-block;
        padding: 5px;
        margin: 5px;
        border-radius: 50px;
      }
    }
  }
}
</style>
<style lang="less" scoped>
.van-field {
  border-radius: 50px;
  border: 1px solid #ccc;
}
.van-field__control {
}
.van-cell {
  padding: 0px;
}
@media screen and (min-width: 750px) {
  .van-field {
    border: 1px solid #ccc;
    width: 750px;
    height: 50px;
    line-height: 1rem;
  }
  .van-field__control {
  }
  .van-cell {
  }
}
</style>
